import React, { useState, useLayoutEffect } from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import styled from 'styled-components'
import {LeftSection, RightSection, PageWrapper, Row, Span, Flex, Title} from '../components/UI'
import { breakpoints, apiURL, apiResponseStatus } from '../utils/helper'
import pattern from '../images/pattern-thanks.jpg'
import employee from '../images/employee.jpg'
import entrance from '../images/up-entrance.jpg'
import Icons from '../svg/icon-row.svg'
import axios from 'axios'
import _ from 'lodash'
import { navigate } from 'gatsby'

const MyRow = styled(Row)`
    @media ${breakpoints.tablet} {
        align-items: center;
    }
    
    @media ${breakpoints.desktop} {
        padding: 24px 0 60px;
        width: 100%;
        max-width: 850px;
        margin: 0 auto;
    } 
    
    &.head {
        color: ${props => props.theme.colors.red};
    }
`

const MyLeftSection = styled(LeftSection)`
    margin: -24px 30px 0 0;
    
    svg {
        width: 100%;
        margin-bottom: 36px;
        
        @media ${breakpoints.tablet} {
            width: auto;
            margin-bottom: 0;
        }
    }
`

const Header = styled(Flex)`
    width: 100%;
    height: auto;
    background: url(${pattern}) 50% 50% no-repeat;
    background-size: cover;
    padding: 108px 0 42px !important;
    
    @media ${breakpoints.desktop} {
        padding: 150px 0 0px !important;
    }
    
    h1 {
        color: ${props => props.theme.colors.red};
        margin-bottom: 36px;
    }
    
    span {
        color: #ffffff;
        margin-bottom: 60px;
    }
`

const Image = styled.img`
    width: 100%;
    margin-bottom: 20px;
`

const WelkomPage = ({location}) => {
    const [ statusCode, setStatusCode ] = useState(null)
    const query = new URLSearchParams(_.get(location, 'search'))

    useLayoutEffect( () => {
        const email = query.get('email')
        const token = query.get('token')

        if (!email || !token) {
            navigate('/')
        }

        async function confirmEmail() {
            try {
                const res = await axios.post(
                    apiURL('/profiles/confirm'),
                    { email, token })
                setStatusCode(_.get(res, 'status'))
            } catch (res) {
                const status = apiResponseStatus(res)
                setStatusCode(status)
                navigate('/')
            }
        }
        confirmEmail()
        // eslint-disable-next-line
    }, [])

    return (
        <Layout location={location}>
            {!_.isNull(statusCode) && statusCode <= 299 &&
                <>
                    <SEO title="Welkom | Je bent nu aangemeld voor de UP Club"
                        description="Je hebt je succesvol aangemeld voor de UP Club. Binnenkort spaar je bij elk bezoek automatisch voor korting of een upgrade op je wasbeurt."/>
                    <Header>
                        <PageWrapper>
                            <MyRow>
                                <MyLeftSection>
                                    <Title>Welkom bij de UP Club!</Title>
                                    <Span>
                                        Leuk dat je je hebt aangemeld voor de UP Club. Spaar bij elk bezoek automatisch
                                        voor korting of een upgrade op je wasbeurt. Daarnaast houden we je op de hoogte van
                                        interessante acties.
                                    </Span>
                                    <Icons/>
                                </MyLeftSection>
                                <RightSection>
                                    <Image src={employee}/>
                                    <Image src={entrance}/>
                                </RightSection>
                            </MyRow>
                        </PageWrapper>
                    </Header>
                </>
            }
        </Layout>
    )
}

export default WelkomPage
